<template>
  <v-card class="py-5" elevation="0">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >สรุปผลลัพธ์การต่อภาษี</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center" dense>
        <v-col cols="12">
          <v-data-table
            class="elevation-2"
            :headers="headers"
            :items="model.process_array"
            :search="search"
            :loading="loading"
            :loading-text="$table_loading_text"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.extension_tax_fee`]="{ item }">
              <div class="text-center">
                {{ item.extension_tax_fee | numeral('0,0.00') }}
              </div>
            </template>

            <template v-slot:[`item.transportation_fee`]="{ item }">
              <div class="text-center">
                {{ item.transportation_fee | numeral('0,0.00') }}
              </div>
            </template>

            <template v-slot:[`item.margin_minus`]="{ item }">
              <div v-if="item.margin_minus" class="red--text text-center">
                {{ item.margin_minus | numeral('0,0.00') }}
              </div>
              <div v-else class="text-center">-</div>
            </template>

            <template v-slot:[`item.margin_plus`]="{ item }">
              <div v-if="item.margin_plus" class="success--text text-center">
                {{ item.margin_plus | numeral('0,0.00') }}
              </div>
              <div v-else class="text-center">-</div>
            </template>

            <template v-slot:[`item.is_refund_or_charge`]="{ item }">
              <div v-if="item.margin < 0">
                <v-btn
                  color="success"
                  class="white--text"
                  :outlined="!item.is_refund_or_charge"
                  @click="onClickRefundOrCharge(item, true)"
                  small
                  >คืนเงิน</v-btn
                >
                <span class="mx-1"></span>
                <v-btn
                  color="grey"
                  class="white--text"
                  :outlined="item.is_refund_or_charge"
                  @click="onClickRefundOrCharge(item, false)"
                  small
                  >ไม่คืนเงิน</v-btn
                >
              </div>
              <div v-else-if="item.margin > 0">
                <v-btn
                  color="red"
                  class="white--text"
                  :outlined="!item.is_refund_or_charge"
                  @click="onClickRefundOrCharge(item, true)"
                  small
                  >เก็บเงินเพิ่ม</v-btn
                >
                <span class="mx-1"></span>
                <v-btn
                  color="grey"
                  class="white--text"
                  :outlined="item.is_refund_or_charge"
                  @click="onClickRefundOrCharge(item, false)"
                  small
                  >ไม่เก็บเพิ่ม</v-btn
                >
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <template v-if="item.margin < 0">
                <div v-if="item.is_refund_or_charge" class="text-center">
                  คืนเงิน {{ item.margin_plus }}
                </div>
                <div v-if="!item.is_refund_or_charge" class="text-center">
                  ไม่คืนเงิน
                </div>
              </template>
              <template v-else-if="item.margin > 0">
                <div v-if="item.is_refund_or_charge" class="text-center">
                  เก็บเงินเพิ่ม {{ item.margin_minus }}
                </div>
                <div v-if="!item.is_refund_or_charge" class="text-center">
                  ไม่เก็บเพิ่ม
                </div>
              </template>
              <template v-else>
                <div class="text-center">-</div>
              </template>
            </template>

            <template v-slot:footer>
              <table-pagination
                :page.sync="page"
                :itemsPerPage.sync="itemsPerPage"
                :length="pageCount"
              />
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="6" class="text-center my-5 pt-5">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="text-left primary--text fz-3"
                  >สรุปรายจ่ายค่าภาษี</v-col
                >
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2">1</v-col>
                    <v-col class="text-left">ยอดต่อภาษี ขนส่งเก็บ</v-col>
                    <v-col cols="2">{{
                      model.report.total_transportation
                    }}</v-col>
                    <v-col cols="2">{{
                      transportation_fee | numeral('0,0')
                    }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2">2</v-col>
                    <v-col class="text-left"
                      >ค่าช่องภาษีรถยนต์ ({{
                        model.report.cost_extension_car_tax
                      }}
                      บาท)</v-col
                    >
                    <v-col cols="2">{{ model.report.car_count }}</v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="model.report.total_cost_extension_car_tax"
                        :value="
                          model.report.total_cost_extension_car_tax
                            | numeral('0,0.00')
                        "
                        type="number"
                        outlined
                        dense
                        background-color="white"
                        single-line
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <!--<v-col cols="2">{{ model.report.total_cost_extension_car_tax | numeral('0,0') }}</v-col>-->
                  </v-row>
                  <v-row>
                    <v-col cols="2">3</v-col>
                    <v-col class="text-left"
                      >ค่าช่องภาษีรถจักรยานยนต์ ({{
                        model.report.cost_extension_motorbike_tax
                      }}
                      บาท)</v-col
                    >
                    <v-col cols="2">{{ model.report.motorcycle_count }}</v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="
                          model.report.total_cost_extension_motorbike_tax
                        "
                        :value="model.report.total_cost_extension_motorbike_tax"
                        type="number"
                        outlined
                        dense
                        background-color="white"
                        single-line
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                    <!--<v-col cols="2">{{ model.report.total_cost_extension_motorbike_tax | numeral('0,0') }}</v-col>-->
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col class="primary--text fz-3 text-right"
                      >รวมทั้งสิ้น</v-col
                    >
                    <v-col cols="2" class="primary--text fz-3">{{
                      total_price | numeral('0,0')
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2" class="text-center my-5 pt-0">
          <v-card cols="4" class="mt-5">
            <v-col cols="12" class="text-left primary--text fz-3"
              >ช่องทางการจ่ายให้ขนส่ง
            </v-col>
            <v-card-text class="py-2">
              <v-btn
                :color="model.payment_type == 1 ? 'primary' : 'grey'"
                text
                block
                @click="model.payment_type = 1"
                >เงินสด</v-btn
              >
              <v-divider class="my-1" />
              <v-btn
                :color="model.payment_type == 2 ? 'primary' : 'grey'"
                text
                block
                @click="model.payment_type = 2"
                >โอน</v-btn
              >
              <v-divider class="my-1" />
              <template v-if="model.payment_type == 2">
                <div class="fz-3 pt-3">ธนาคาร</div>
                <v-select
                  class="my-5 mb-2"
                  :items="banks"
                  v-model="model.pay_reference"
                  label=""
                  dense
                  hide-details
                  outlined
                ></v-select>
                <v-row class="mt-1 ml-0" v-if="this.banks.length == 1">
                  <p color="red" class="red--text">
                    ยังไม่ไม่มีบัญชีรับโอน ให้เพิ่มบัญชีโดย
                  </p>
                  &nbsp;
                  <p
                    type="button"
                    class="text-decoration-underline red--text"
                    color="red"
                    @click="addBank()"
                  >
                    คลิกที่นี่
                  </p></v-row
                >
              </template>
              <div
                v-if="model.payment_type != 1 && model.pay_reference == ''"
                class="error--text ml-2 mb-2"
              >
                กรุณาระบุ
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8" class="d-flex">
          <v-btn outlined class="px-5" color="grey" @click="close()">
            ยกเลิก
          </v-btn>
          <v-spacer />
          <v-btn
            class="px-10 mr-5"
            color="success"
            @click="save(false)"
            :loading="loading"
            :disabled="loading"
          >
            บันทึก ไม่สร้างรายจ่ายภาษี
          </v-btn>
          <v-btn
            class="px-10"
            color="primary"
            @click="save(true)"
            :loading="loading"
            :disabled="loading"
          >
            บันทึก และสร้างรายจ่ายภาษี
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import * as moment from 'moment';
export default {
  props: ['process_array'],
  banks: [],
  errors: {
    pay_reference: '',
  },
  data() {
    return {
      search: '',
      selected: [],
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      model: {
        process_array: [],
        report: {
          car_count: 0,
          cost_extension_car_tax: 0,
          cost_extension_motorbike_tax: 0,
          motorcycle_count: 0,
          total_cost_extension_car_tax: 0,
          total_cost_extension_motorbike_tax: 0,
          total_price: 0,
          total_transportation: 0,
          transportation_fee: 0,
        },
        payment_type: 1,
        pay_reference: '',
      },
      headers: [
        { text: 'ทะเบียน', value: 'car_plate', filterable: false },
        { text: 'จังหวัด', value: 'province', filterable: false },
        { text: 'ค่าภาษี', value: 'extension_tax_fee', filterable: false },
        { text: 'ขนส่งเก็บ', value: 'transportation_fee', filterable: false },
        { text: 'เก็บเงินเพิ่ม', value: 'margin_minus', filterable: false },
        { text: 'คืนเงิน', value: 'margin_plus', filterable: false },
        { text: '', value: 'is_refund_or_charge', filterable: false },
        { text: 'การดำเนินการ', value: 'action', filterable: false },
      ],
    };
  },
  created() {
    this.getBanks();
  },
  mounted() {
    this.getData();
  },
  methods: {
    addBank() {
      let routeData = this.$router.resolve({
        name: 'setting-business-branch-update',
        params: { id: this.$store.state.selected_branch._id },
        query: { dialogUpdate: 'true' },
      });
      window.open(routeData.href, '_blank');
    },
    async downloadItemExcel() {
      console.log('test');
      this.loading = true;
      let filter1 = {
        branch_id: this.$store.state.selected_branch._id,
        item_per_page: 10,
        page: 1,
        category_id: '',
        start_with: '',
        sub_category_id: '',
        start_date: moment().add(-30, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      };

      let itemDowload = '';
      let body1 = {
        token: this.$jwt.sign(filter1, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense/get_extension_tax_expense`, body1)
        .then((res) => {
          itemDowload = res.result.data[0];

          let filter2 = {
            expense_id: itemDowload._id,
          };
          let body = {
            token: this.$jwt.sign(filter2, this.$privateKey, {
              noTimestamp: true,
            }),
          };
          this.$axios({
            method: 'post',
            url: `${this.$baseUrl}/expense/download_expense_cartax`,
            responseType: 'blob',
            data: body,
          })
            .then((response) => {
              this.forceFileDownload(
                response,
                'ผลลัพธ์การต่อภาษี' + moment().format('YYYY-MM-DD') + '.xlsx'
              );
            })
            .catch((err) => {
              console.log('err', err);
              this.$alertServerError({ title: err.error_message });
            });
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });

      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    async getData() {
      this.loading = true;
      let payload = {
        branch_id: this.$store.state.selected_branch._id,
        process_array: this.process_array,
      };
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/summary_tax_extension`, body)
        .then((res) => {
          console.log(res);
          this.model.report = res.result.report;
          this.model.process_array = this.prepareProcessArray(
            res.result.process_array
          );
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    prepareProcessArray(process_array) {
      let list = [];
      if (
        process_array &&
        process_array.length &&
        typeof process_array === 'object'
      ) {
        for (var i = 0; i < process_array.length; i++) {
          var o = Object.assign({}, process_array[i]);
          if (o.margin <= 0) {
            o.margin_plus =
              Math.ceil((o.margin * -1 + Number.EPSILON) * 100) / 100;
            o.margin_plus = o.margin_plus == 0.01 ? 0 : o.margin_plus;
          } else {
            o.margin_minus = Math.ceil((o.margin + Number.EPSILON) * 100) / 100;
            o.margin_minus = o.margin_minus == -0.01 ? 0 : o.margin_minus;
          }
          o.is_refund_or_charge = false;
          list.push(o);
        }
      }
      return list;
    },
    close() {
      this.$router
        .push({ name: 'follow-tax', params: { tab: 1 } })
        .catch(() => {});
    },
    async save(is_create_report) {
      this.valid = true;
      if (this.model.payment_type != 1 && this.model.pay_reference == '') {
        this.valid = false;
      }
      if (this.valid) {
        this.loading = true;
        let process_array = this.model.process_array.map((x) => {
          return {
            process_id: x._id,
            is_refund_or_charge: x.is_refund_or_charge,
          };
        });
        let payload = {
          branch_id: this.$store.state.selected_branch._id,
          is_create_report: is_create_report,
          process_array: process_array,
          car_bribe: parseFloat(this.model.report.total_cost_extension_car_tax),
          bike_bribe: parseFloat(
            this.model.report.total_cost_extension_motorbike_tax
          ),
          payment_type: this.model.payment_type,
          pay_reference: this.model.pay_reference,
        };
        let body = {
          token: this.$jwt.sign(payload, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        this.$alertConfirm({ title: 'ยืนยัน', text: '' }).then(
          async (result) => {
            if (result.isConfirmed) {
              await this.$axios
                .post(
                  `${this.$baseUrl}/process/record_tax_extension_result`,
                  body
                )
                .catch((err) => {
                  console.log('err', err);
                  this.$alertServerError({ title: err.error_message });
                });
            }
            this.loading = false;
            if (!is_create_report) {
              this.close();
            } else {
              this.$alertSuccess({ title: 'สร้างรายจ่ายแล้ว' });
              this.close();
            }
          }
        );
      }
    },
    onClickRefundOrCharge(item, value) {
      item.is_refund_or_charge = value;
    },
    async getBanks() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$store.state.selected_branch._id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/branch/get_bank_account`, body)
        .then(async (res) => {
          this.banks = res.result;
          this.banks.unshift('');
        })
        .catch((err) => {
          console.log('err', err);
        });
    },
  },
  computed: {
    transportation_fee() {
      let price = 0;
      this.model.process_array.forEach((item) => {
        price += item.transportation_fee;
        // price += item.is_refund_or_charge
        // ? item.transportation_fee
        // : item.extension_tax_fee;
      });
      return price;
    },
    total_price() {
      let price = 0;
      price =
        parseFloat(this.transportation_fee) +
        parseFloat(this.model.report.total_cost_extension_car_tax) +
        parseFloat(this.model.report.total_cost_extension_motorbike_tax);

      return price;
    },
  },
  watch: {
    'model.payment_type': function (newVal, oldVal) {
      if (newVal == 1) this.model.pay_reference = '';
      else if (newVal == 2) this.model.pay_reference = this.banks[0];
    },
  },
};
</script>
